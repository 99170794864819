import React from "react";
import styled from "styled-components";
import TimeImg from "../../pages/landing/components/journey/assets/images/3.png";
const UserCard = ({ item }) => {
  let userName = item.name;
  let nameSpit = userName.split(" ");
  return (
    <CardWrapper className="card-wrap">
      <ProfileWrapper>
        <img src={item.image} alt="" />
        <p>
          {nameSpit[0]} <span>{nameSpit[1]}</span>
        </p>
      </ProfileWrapper>
      <ContentWrapper className="ContentWrapper">
        <p>{item.description}</p>
        <p>{item.details}</p>
        <p>
          <img src={TimeImg} alt="" />
          {item.dateTime}
        </p>
      </ContentWrapper>
    </CardWrapper>
  );
};

export default UserCard;

const CardWrapper = styled.div`
  background: #010101;
  padding: 0;
  height: 100%;
  &:hover {
    .ContentWrapper {
      background: #212121;
    }
  }
`;
const ProfileWrapper = styled.div`
  display: flex;
  align-items: end;
  padding: 30px;
  p {
    margin-bottom: 35px;
    margin-left: 15px;
    font-style: italic;
    font-size: 25px;
    line-height: 35px;
    width: calc(100% - 185px);
    span {
      display: block;
      font-weight: 900;
      font-size: 30px;
      white-space: pre-wrap;
      word-wrap: break-word;
      position: relative;
    }
  }
  img {
    width: 180px;
    height: 180px;
    border-radius: 50%;

    @media (min-width: 1420px) and (max-width: 1559px) {
      width: 180px;
      height: 180px;
    }
    @media (min-width: 1366px) and (max-width: 1419px) {
      width: 175px;
      height: 175px;
    }
    @media (min-width: 1320px) and (max-width: 1365px) {
      width: 160px;
      height: 160px;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 30px 20px 30px;
  p {
    &:nth-child(1) {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.25px;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:nth-child(2) {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 2;
    }

    &:nth-child(3) {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14.4px;
      letter-spacing: 0.4px;
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        border-radius: 50px;
        border: 1px solid #ffffff;
        height: 29px;
        width: 30px;
      }
    }
  }
`;
