import React from "react";
import styled from "styled-components";
import Logo from "../../../landing/assets/images/logo.png";
import Bg from "./assets/images/1.png";
import { Col, Row } from "react-bootstrap";
import DiscSvg from "../footer/assets/images/footer.svg";
const Footer = () => {
  return (
    <Wrapper>
      <ContainerCustom>
        <LogoWrapper>
          <img src={Logo} alt="" />
        </LogoWrapper>
        <RowWrapper className="m-0">
          <ColWrapper className="p-0 mb-1" xs={12} sm={12} md={3}></ColWrapper>
          <ColWrapper className="p-0 mb-1" xs={12} sm={12} md={3}>
            <TitleWrapper>About Us</TitleWrapper>
            <ContentWrapper>
              <Description>
                At PASS, our PURPOSE is simple: Whether it's for a session or
                ten years, we want to use sport as a vehicle to instill values
                in young players that will help them developto be the best
                version of themselves.
              </Description>
            </ContentWrapper>
          </ColWrapper>
          <ColWrapper className="p-0 mb-1" xs={12} sm={12} md={3}>
            <TitleWrapper>Featured Services</TitleWrapper>
            <ContentWrapper>
              <ul>
                <li>
                  <Description> Pass Programs</Description>
                </li>
                <li>
                  <Description> Terms & Conditions</Description>
                </li>
                <li>
                  <Description>Safeguarding and Welfare policy</Description>
                </li>
              </ul>
            </ContentWrapper>
          </ColWrapper>
          <ColWrapper className="p-0 mb-1" xs={12} sm={12} md={3}>
            <TitleWrapper>PASS Abu Dhabi</TitleWrapper>
            <ContentWrapper>
              <Description style={{ marginBottom: "-10px" }}>
                Masdar City, Abu Dhabi
              </Description>
              <Description>info@passabudhabi.com</Description>
              <Description>+971-585107277</Description>
            </ContentWrapper>
          </ColWrapper>
        </RowWrapper>
      </ContainerCustom>
    </Wrapper>
  );
};

export default Footer;

const ContainerCustom = styled.div`
  padding: 0 10% 0 10%;
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 0 6% 0 6%;
  }
`;
const Wrapper = styled.div`
  background-color: #1e1e1e;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 5% 0 5% 0;
  color: #fff;
  font-family: "Poppins";
`;
const LogoWrapper = styled.div`
  img {
    object-fit: cover;
  }
`;
const TitleWrapper = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 15px;
`;
const RowWrapper = styled(Row)`
  padding: 60px 0 75px 0;
`;
const ColWrapper = styled(Col)`
  padding-right: 30px !important;
`;
const ContentWrapper = styled.div`
  ul {
    li {
      list-style-type: disc;
      position: relative;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
      &:hover {
        transform: translateX(20px);
      }
      &::marker {
        display: none;
      }
      &::before {
        position: absolute;
        content: "";
        background-image: url(${DiscSvg});
        height: 20px;
        width: 22px;
        left: -25px;
        top: 8px;
        background-size: contain;
      }
    }
  }
`;
const Description = styled.p`
  color: #b6b6b6;
  font-size: 15px;
  font-weight: 300;
  line-height: 35px;
  margin: 0;
`;
