import React from "react";
import styled from "styled-components";
import SearchIcon from "./assets/images/1.png";

const PsSearchfield = ({ placeholder, value, handleChange, className }) => {
  return (
    <Wrapper>
      <input
        className={className}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <ButtonWrapper>
        <button>
          <img src={SearchIcon} alt="" />
          Find Events
        </button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PsSearchfield;

const Wrapper = styled.div`
  font-family: "Urbanist";
  position: relative;
  input {
    background: #141414;
    width: 100%;
    border-top-left-radius: 4px;
    border: 1px solid #262626;
    border-top-right-radius: 4px;
    padding: 25px 15px 25px 15px;
    color: #666666;
    font-size: 15px;
    &:focus-visible {
      outline: none !important;
    }
  }
  button {
    background: #4067e8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 12px 12px;
    gap: 10px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 14px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;
const ButtonWrapper = styled.div``;
