import React from "react";
import styled from "styled-components";
import { PsLoginCard } from "../../components/PsLoginCard";
import Grid from "@mui/material/Grid";
import LoginBg from "./asset/img/bg/login-left-bg.png";
import SignupBg from "./asset/img/bg/signup-left-bg.png";
import ResetBg from "./asset/img/bg/reset-left-bg.png";
import ElipsisLoginLeft from "./asset/img/bg/Ellipse-left.png";
import ElipsisLoginRight from "./asset/img/bg/Ellipse-right.png";
import ElipsisSignupLeft from "./asset/img/bg/Ellipse-signup-left.png";
import ElipsisSignupRight from "./asset/img/bg/Ellipse-signup-right.png";
import ElipsisResetLeft from "./asset/img/bg/Ellipse-reset-left.png";
import ElipsisResetRight from "./asset/img/bg/Ellipse-reset-right.png";

export const LoginPage = () => {
  const [formNumber, setFormNumber] = React.useState(0);
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={8} className="left-side">
          <img
            src={
              formNumber === 0
                ? LoginBg
                : formNumber === 1
                ? SignupBg
                : formNumber === 2
                ? ResetBg
                : ""
            }
            className="login-Bg"
          />
        </Grid>
        <Grid item xs={4} className="right-side">
          <LoginCardWrapper
            className={
              formNumber === 0
                ? "login-image-style"
                : formNumber === 1
                ? "signup-image-style"
                : formNumber === 2
                ? "reset-image-style"
                : ""
            }
          >
            <img
              className="leftEllipse"
              src={
                formNumber === 0
                  ? ElipsisLoginLeft
                  : formNumber === 1
                  ? ElipsisSignupLeft
                  : formNumber === 2
                  ? ElipsisResetLeft
                  : ""
              }
              alt="Elipsis"
            />
            <PsLoginCard
              className="login-card"
              formNumber={formNumber}
              setFormNumber={setFormNumber}
            />
            <img
              className="rightEllipse"
              src={
                formNumber === 0
                  ? ElipsisLoginRight
                  : formNumber === 1
                  ? ElipsisSignupRight
                  : formNumber === 2
                  ? ElipsisResetRight
                  : ""
              }
              alt="Elipsis"
            />
          </LoginCardWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #030303;
  width: 100%;
  min-height: 100vh;
  padding: 120px 10% 5% 10%;
  color: #fff;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 120px 6% 5% 6%;
  }
  h3 {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.5px;
  }
  .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: unset !important;
    .login-Bg {
      width: 80%;
      height: auto;
    }
  }
  .right-side {
    height: 100%;
    width: 100%;
  }
  .MuiGrid-container {
    align-items: center;
  }
`;
const LoginCardWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  min-height: 600px;
  height: 100%;
  position: relative;
  .leftEllipse {
    position: absolute;
    left: -17px;
    top: -15px;
    width: 170px;
    height: auto;
  }
  .rightEllipse {
    position: absolute;
    right: -11px;
    bottom: -40px;
    width: 100px;
    height: auto;
  }
  .login-card {
    position: relative;
    background: rgb(0 0 0 / 20%) !important;
    backdrop-filter: blur(5px);
    z-index: 2;
  }

  &.login-image-style {
    .leftEllipse {
      left: -130px;
      top: -40px;
    }
    .rightEllipse {
      right: 20px;
      bottom: -10px;
    }
  }
  &.signup-image-style {
    .leftEllipse {
      left: -60px;
      top: -35px;
    }
    .rightEllipse {
      right: -12px;
      bottom: -15px;
      width: 110px;
    }
  }
  &.reset-image-style {
    .leftEllipse {
      left: -55px;
      top: -25px;
    }
    .rightEllipse {
      right: -10px;
      bottom: -30px;
      width: 110px;
    }
  }
`;
