import React from "react";
import styled from "styled-components";

const InnerButton = ({ title, handleButtonClick, image }) => {
  return (
    <ButtonWrapper className={` button-block ${image && "image-align"}`}>
      <button onClick={handleButtonClick}>
        {title}
        {image ? <img src={image} alt="" /> : ""}
      </button>
    </ButtonWrapper>
  );
};

export default InnerButton;
const ButtonWrapper = styled.div`
  width: 100%;
  button {
    border: 1px solid #262626;
    padding: 10px 20px;
    color: #98989a;
    font-size: 14px;
    border-radius: 8px;
    width: 100%;
  }
  &.image-align {
    button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        height: 12px;
        width: 12px;
        border-radius: 0px;
      }
    }
  }
`;
