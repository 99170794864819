import React, { useEffect } from "react";
import styled from "styled-components";
import HandShake from "./assets/handshake.png";
import BgVideo from "../../assets/video/Bg.mp4";
import Nav from "./Nav";
import AOS from "aos";
import "aos/dist/aos.css";
const NavPage = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Wrapper>
      <Nav />
      <VideoWrapper autoPlay muted loop>
        <source src={BgVideo} type="video/mp4" />
      </VideoWrapper>
      <ContentWrapper>
        <ContentSubWrapper>
          <h6
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="100"
          >
            Join the
          </h6>
          <CustomHeader
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="200"
          >
            Revolution
          </CustomHeader>
          <SubLabel
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="300"
          >
            An Interactive Way to Play and Learn
          </SubLabel>
          <StyledParaTag
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="400"
          >
            Yet, the club's impact extends beyond the confines of wins and
            losses. It serves as a hub of community engagement, hosting outreach
            programs, charity drives, and youth initiatives that embody the
            spirit of giving back.
          </StyledParaTag>
          <ButtonWrapper>
            <button
              className="custom-styled-btn"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-delay="500"
            >
              <img src={HandShake} alt="" className="img-fluid" />
              <span>START HERE</span>
            </button>
          </ButtonWrapper>
        </ContentSubWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NavPage;

const ContentSubWrapper = styled.div``;
const Wrapper = styled.div`
  height: 100vh;
  position: relative;
`;

const CustomHeader = styled.h1`
  background: linear-gradient(
    90deg,
    #9b5de5,
    #f15bb5,
    #fee440,
    #00bbf9,
    #00f5d4
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: clamp(30px, 5vw, 85px);
  font-weight: 900;
  @keyframes gradientAnimation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 25%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
const ContentWrapper = styled.div`
  font-family: "Poppins";
  top: 0;
  position: absolute;
  color: #ffffff;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 0 0 7%;
  align-items: center;
  justify-content: flex-start;
  h6 {
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 0px;
  }
`;
const SubLabel = styled.h6`
  font-size: 30px !important;
  font-weight: 300;
  margin-bottom: 0px;
`;
const StyledParaTag = styled.p`
  width: 100%;
  max-width: 550px;
  margin: 20px 0 0 0;
  line-height: 1.8;
`;
const ButtonWrapper = styled.div`
  padding: 30px 0 0 0;
  display: inline-block;
  button {
    display: flex;
    align-items: center;
    padding: 8px 30px;
    font-size: 15px;
    width: 100%;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2.5px;
    border-image-source: linear-gradient(
      to left,
      #ab5cdc,
      #fdd64b,
      #5fcab4,
      #00bbf9
    );
    -webkit-transition: background-image 2s; /* For Safari 3.0 to 6.0 */
    transition: background-image 2s;
    &:hover {
      background-image: linear-gradient(
        to left,
        rgb(171 92 220 / 40%),
        rgb(253 214 75 / 40%),
        rgb(95 202 180 / 40%),
        rgb(0 187 249 / 40%)
      );
    }
    img {
      margin: 0 20px 0 0;
    }
    span {
      padding: 0;
    }
  }
`;
const VideoWrapper = styled.video`
  width: 100%;
  height: 100%;
  object-position: right;
  object-fit: cover;
`;
