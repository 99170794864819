import Img1 from "../value-vehicles/assets/images/1.svg";
import Img2 from "../value-vehicles/assets/images/2.png";
import Img3 from "../value-vehicles/assets/images/3.svg";
import Img4 from "../value-vehicles/assets/images/4.svg";
import Img5 from "../value-vehicles/assets/images/5.svg";
import Img6 from "../value-vehicles/assets/images/6.svg";
import Img7 from "../value-vehicles/assets/images/7.png";
import Img8 from "../value-vehicles/assets/images/8.svg";
import Img9 from "../value-vehicles/assets/images/9.svg";
import Img10 from "../value-vehicles/assets/images/10.svg";
import Img11 from "../value-vehicles/assets/images/11.svg";
import Img12 from "../value-vehicles/assets/images/12.svg";
import Img13 from "../value-vehicles/assets/images/13.svg";

const data = [
  {
    image: Img1,
    title: "Netball for5-14 year olds",
  },
  {
    image: Img2,
    title: "Largest, private football league inthe UAE",
  },
  {
    image: Img3,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
  {
    image: Img4,
    title: "Football for a range of skill levels for 2-16 year olds",
  },
  {
    image: Img5,
    title: "Partners with charitable organisations in Ghana ",
  },
  {
    image: Img6,
    title:
      "Pathway links with professional football clubs in UAE and internationally ",
  },
  {
    image: Img7,
    title: "Mass participation events and birthday parties ",
  },
  {
    image: Img8,
    title: "UAE nurseries, schools, and universities ",
  },
  {
    image: Img9,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
  {
    image: Img10,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
  {
    image: Img11,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
  {
    image: Img12,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
  {
    image: Img13,
    title:
      "Community outreach for People of Determination with Special Olympics UAE ",
  },
];
export default data;
