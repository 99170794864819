import React, { useEffect } from "react";
import styled from "styled-components";
import HeadingLabel from "../../components/HeadingLabel";
import data from "./data";
import { Col, Row } from "react-bootstrap";
import ProductCard from "./components/productcard";
import GradientText from "../../components/gradientText";
import LeftIcon from "./assets/images/icons_arrow-left.png";
import RightIcon from "./assets/images/icons arrow-right.png";
import { LabelMedium } from "../../components/PsLabel";
const Store = () => {
  const [shownData, setShownData] = React.useState("");
  useEffect(() => {
    setShownData(data.slice(0, 10));
  }, []);
  return (
    <Wrapper>
      <HeadingLabel title="Store" />
      <LabelMedium weight="bold" className="our-products-block" color="#ffffff">
        Our Products
      </LabelMedium>
      <ContentWrapper>
        <GradientText title={"Explore Our"} gradientTitle={"Products"} />
        <NavWrapper>
          <div
            onClick={() => {
              setShownData(data.slice(0, 10));
            }}
          >
            <img src={LeftIcon} alt="" />
          </div>
          <div
            onClick={() => {
              setShownData(data.slice(11, 20));
            }}
          >
            <img src={RightIcon} alt="" />
          </div>
        </NavWrapper>
      </ContentWrapper>

      <Row>
        {shownData &&
          shownData?.map((item) => {
            return (
              <Col
                className="mb-2 store-col"
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
                xs={12}
                sm={6}
                md={3}
              >
                <ProductCard item={item} />
              </Col>
            );
          })}
      </Row>
    </Wrapper>
  );
};

export default Store;
const Wrapper = styled.div`
  background: #181818;
  padding: 120px 10% 5% 10%;
  font-family: "Poppins";
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 120px 5% 5% 5%;
  }
  .title-label {
    padding-bottom: 15px;
    label {
      padding-top: 0px;
      padding-bottom: 40px;
    }
  }
  .gradient-content {
    h3 {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 3px;
      span {
        font-size: 28px;
      }
    }
  }
  .our-products-block {
    position: relative;
    padding-bottom: 25px;
    padding-left: 30px;
    &::before {
      content: "";
      height: 38px;
      width: 18px;
      position: absolute;
      border-radius: 4px;
      background: linear-gradient(45deg, #406ae5 0%, #46e782 100%);
      left: 0;
      top: -9px;
    }
  }
  .store-col {
    @media (min-width: 768px) and (max-width: 991px) {
      flex: 0 0 auto;
      width: 33.3%;
    }
  }
`;
const NavWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 25%;
  justify-content: end;
  > div {
    background: #424141;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
`;
