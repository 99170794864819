import Img1 from "./assets/images/1.png";
import Img2 from "./assets/images/2.png";
import Img3 from "./assets/images/3.png";
import Img4 from "./assets/images/4.png";
import Img5 from "./assets/images/5.png";
import Img6 from "./assets/images/6.png";
import Img7 from "./assets/images/7.png";
import Img8 from "./assets/images/8.png";
import Img9 from "./assets/images/9.png";
import Img10 from "./assets/images/10.png";

const data = [
  {
    image: Img1,
  },
  {
    image: Img2,
  },
  {
    image: Img3,
  },
  {
    image: Img4,
  },
  {
    image: Img5,
  },
  {
    image: Img6,
  },
  {
    image: Img7,
  },
  {
    image: Img8,
  },
  {
    image: Img9,
  },
  {
    image: Img10,
  },
];
export default data;
