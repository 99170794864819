import React from "react";
import styled from "styled-components";
import { LabelLarge, LabelMedium } from "../../../../components/PsLabel";
import { PsButton } from "../../../../components/PsButton";
import { useNavigateToRoute } from "../../../../utils/common";

const BookingCard = ({ item }) => {
  const redirect = useNavigateToRoute();

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={item.imageUrl} alt="" />
        <LabelLarge className="next-session">{item.nextSession}</LabelLarge>
      </ImageWrapper>
      <ContentWrapper>
        <FlexWrapper>
          <span>{item.date}</span>
          <span>{item.program}</span>
        </FlexWrapper>

        <LabelLarge className="title-label">{item.title}</LabelLarge>
        <LabelMedium className="location-label">
          Location: {item.location}
        </LabelMedium>
        <LabelMedium>{item.dateRange}</LabelMedium>
        <LabelMedium>{item.time}</LabelMedium>

        <LabelMedium className={`slot-label `}>
          {item.slots < 5 ? "Few Slots Left HURRY!" : ""}
        </LabelMedium>

        <PsButton
          onClick={() => {
            redirect("/login");
          }}
          borderRadius={"8px"}
          label="Book Now"
          bgColor={"linear-gradient(45deg, #406AE5 0%, #46E782 100%)"}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default BookingCard;
const Wrapper = styled.div`
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #262626;
  padding: 15px;
  height: 100%;

  .title-label {
    font-size: 17px;
    font-weight: 700;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .location-label {
    color: #ff8a00;
    font-size: 15px;
  }
`;
const ContentWrapper = styled.div`
  padding-top: 15px;
  button {
    margin-top: 15px;
  }
  .slot-label {
    color: #f44336;
    font-weight: 900;
    transition: 0.5s ease;
    display: block;
    height: 26px;
  }
  .blink {
    visibility: hidden;
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    border: 1px solid #262626;
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  img {
    border-radius: 10px;
    height: 250px;
    object-fit: cover;
    width: 100%;
  }
  .next-session {
    background: rgb(255 255 255 / 10%);
    padding: 5px;
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 25px;
    color: #000000 !important;
    font-size: 13px;
  }
`;
