import React from "react";
import styled from "styled-components";
import { LabelMedium } from "../PsLabel";

const TitleDescriptionLabel = ({ title, description }) => {
  return (
    <ContentWrapper>
      <LabelMedium color="#98989A">{title}</LabelMedium>
      <LabelMedium>{description}</LabelMedium>
    </ContentWrapper>
  );
};

export default TitleDescriptionLabel;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #262626;
  gap: 5px;
  padding: 15px 15px;
  border-radius: 8px;
  width: 100%;
  label:nth-child(2) {
    font-weight: 600;
  }
`;
