import React from "react";
import styled from "styled-components";
import InnerButton from "../../../../components/InnerButton";
import { LabelLarge, LabelMedium } from "../../../../components/PsLabel";

const EventsCard = ({ item }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={item.image} alt="" />
      </ImageWrapper>
      <ContentWrapper>
        <LabelLarge weight="600" color="#ffffff">
          Lorem ipsum dolor
        </LabelLarge>
        <LabelMedium color="#98989A">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor
        </LabelMedium>
      </ContentWrapper>
      <ButtonWrapper>
        <InnerButton title={"View Details"} handleButtonClick={(e) => {}} />
        <InnerButton title={"Book Now"} handleButtonClick={(e) => {}} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default EventsCard;
const Wrapper = styled.div``;
const ImageWrapper = styled.div`
  img {
    border-radius: 12px;
    height: 270px;
    width: 100%;
    object-fit: cover;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 15px;
  .button-block:nth-child(1) {
    button {
      background: #141414;
    }
  }
  .button-block:nth-child(2) {
    button {
      background: #191919;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
`;
