import React from "react";
import styled from "styled-components";
import BookingSessionBlock from "./components/bookingsession";
import NavPage from "./components/nav-page";
import ValueVehicles from "./components/value-vehicles";
import Journey from "./components/journey";
import About from "./components/about";
import WhoWeAre from "./components/WhoWeAre";
import Footer from "./components/footer";
import OurPartners from "./components/partners";

const LandingPage = () => {
  return (
    <Wrapper>
      <NavPage />
      <BookingSessionBlock />
      <WhoWeAre />
      <ValueVehicles />
      <About />
      <Journey />
      <OurPartners />
      <Footer />
    </Wrapper>
  );
};

export default LandingPage;

const Wrapper = styled.div``;
