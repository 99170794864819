import React from "react";
import styled from "styled-components";
const GradientText = ({ title, gradientTitle, description }) => {
  return (
    <ContentWrapper className="gradient-content">
      <h3>
        {title} <span>{gradientTitle}</span>
      </h3>
      <p>{description}</p>
    </ContentWrapper>
  );
};
export default GradientText;
const ContentWrapper = styled.div`
  color: #fff;
  width: 75%;
  h3 {
    font-size: 35px;
    span {
      font-size: 45px;
      font-weight: bold;
      background: linear-gradient(90deg, #4065eb 0%, #62ff49 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    line-height: 2;
    letter-spacing: 1.2px;
    padding: 25px 0 0 0;
  }
`;
