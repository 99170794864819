import Img1 from "../../assets/images/1.png";
import Img2 from "../../assets/images/2.png";
import Img3 from "../../assets/images/3.png";

const data = [
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img1,
    slots: 3,
  },
  {
    date: "26 Mar 2024",
    program: "Foundation (Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img2,
    slots: 6,
  },
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img3,
    slots: 2,
  },
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img1,
    slots: 6,
  },
  {
    date: "26 Mar 2024",
    program: "Foundation (Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img2,
    slots: 6,
  },
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img3,
    slots: 6,
  },
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img1,
    slots: 6,
  },
  {
    date: "26 Mar 2024",
    program: "Foundation (Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img2,
    slots: 1,
  },
  {
    date: "26 Mar 2024",
    program: "(Recreational) Program",
    nextSession: "Next session starts in 26 DAY(s)",
    title: "NETBUSTERS (7 TO 9 YEARS) Boys & Girls",
    location: "Indoor Dome at Hudariyat Island",
    dateRange: "Apr 21 2024 - Jun 30 2024 ( 11 weeks )",
    time: "01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )",
    imageUrl: Img3,
    slots: 2,
  },
];
export default data;
