import React from "react";
import { PsTextField } from "../../components/PsTextField";
import styled from "styled-components";
import { PasswordHideIcon } from "../../assets/img/icon/index";
import { PsButton } from "../../components/PsButton";
import { PsLoginCard } from "../../components/PsLoginCard";

import { PsDropdown } from "../../components/PsDropdown";
import PsSearchfield from "../../components/PsSearchfield";
const dummyData = [
  { id: 1, name: "John", icon: <PasswordHideIcon />, children: null },
  { id: 2, name: "Mathew", icon: <PasswordHideIcon />, children: null },
  { id: 3, name: "Snow", icon: <PasswordHideIcon />, children: null },
];
const TestPage = () => {
  return (
    <Wrapper>
      <PsTextField placeholder="Username" autoComplete="on" />
      <PsTextField placeholder="Password" type="password" />
      <PsButton label="Submit" svgIcon={<PasswordHideIcon />} />
      <PsButton
        label="Submit"
        bgColor={
          "linear-gradient(45deg, rgba(98, 142, 255, 1) 0%, rgba(135, 64, 205, 1) 50%, rgba(88, 4, 117, 1) 100%)"
        }
      />
      <PsButton
        label="submit"
        bgColor={
          "linear-gradient(45deg, rgba(46, 76, 238, 1) 0%, rgba(34, 30, 191, 1) 50%, rgba(4, 15, 117, 1) 100%)"
        }
      />
      <PsButton
        label="submit"
        bgColor={
          "linear-gradient(45deg, rgba(233, 72, 197, 1) 0%, rgba(205, 64, 123, 1) 50%, rgba(117, 4, 45, 1) 100%)"
        }
      />
      <LoginWrapper>
        <PsLoginCard />
      </LoginWrapper>
      <a href="/components/signup">Redirect</a>
      <PsDropdown
        data={dummyData}
        placeholder="Location"
        value=""
        placeholderIcon={<PasswordHideIcon />}
      />

      <PsSearchfield />
    </Wrapper>
  );
};

export default TestPage;

const Wrapper = styled.div`
  background-color: #000000;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
const LoginWrapper = styled.div`
  /* width: 40%; */
`;
