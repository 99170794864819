import React from "react";
import styled from "styled-components";

const LogoCard = ({ item }) => {
  return (
    <LogoWrapper>
      <img src={item.image} alt="" />
    </LogoWrapper>
  );
};

export default LogoCard;
const LogoWrapper = styled.div`
  background: #f6f6f6;
  padding: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #FAFAFA;
    img {
      filter: grayscale(0);
    }
  }
  img {
    height: 115px;
    object-fit: contain;
    background-clip: padding-box;
    mix-blend-mode: multiply;
    filter: grayscale(1);
  }
`;
