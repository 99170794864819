import card1 from "../../assets/img/playercard1.png";
import card2 from "../../assets/img/playercard2.png";
import card3 from "../../assets/img/playercard3.png";
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
import spiral1 from "../../assets/img/spiral1.png";
import spiral2 from "../../assets/img/spiral2.png";
import spiral3 from "../../assets/img/spiral3.png";

export const WhoWeAreData = [
  {
    url: card1,
    topIcon: icon1,
    gradientText: "1200",
    text: "Weekly Participants",
    footerText: " (From 2-16 Years) ",
    spiralImg: spiral1,
  },
  {
    url: card2,
    topIcon: icon2,
    gradientText: "2 sports",
    text: "Football & Netball",
    footerText: "",
    spiralImg: spiral2,
  },
  {
    url: card3,
    topIcon: icon3,
    gradientText: "UAE",
    text: "Founder of the largest football league in the",
    footerText: "",
    spiralImg: spiral3,
  },
];
