import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, { useEffect } from "react";
import styled from "styled-components";
import { VerticalLineIcon } from "../../assets/img/icon";
import Arrow from "./asset/img/Button.svg";

export const PsDropdown = ({
  onChange,
  placeholder,
  disabled,
  value,
  data,
  placeholderIcon,
}) => {
  const [selectvalue, setSelectValue] = React.useState(value);
  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };
  document.addEventListener("DOMContentLoaded", function () {
    const select = document.querySelector(".MuiSelect-select");
    if (select) {
      select.addEventListener("click", function () {
        const expanded = this.getAttribute("aria-expanded") === "true";
        if (expanded) {
          // Add a class to style the arrow
          this.classList.add("expanded");
        } else {
          // Remove the class when the select is collapsed
          this.classList.remove("expanded");
        }
      });
    }
  });
  useEffect(() => {
    if (value !== selectvalue) setSelectValue(value);
    return () => {};
  }, [value]);
  return (
    <Wrapper className="dropdown-wrapper">
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectvalue === "" ? 0 : selectvalue}
          onChange={handleChange}
          disabled={disabled}
        >
          <MenuItem id="homepage-select" value={0} sx={{ display: "none" }}>
            <ListItemIcon>
              {placeholderIcon}
              <VerticalLineIcon />
            </ListItemIcon>
            <ListItemText>{placeholder}</ListItemText>
          </MenuItem>
          {data?.map((data, index) => (
            <MenuItem
              id="homepage-select"
              key={index}
              value={data.id}
              className="dropdown-menu"
            >
              {data.icon && (
                <ListItemIcon>
                  {data.icon} <VerticalLineIcon />
                </ListItemIcon>
              )}
              <ListItemText>{data.name}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-width: 120px;
  padding-left: 3px;
  .MuiFormControl-root {
    background: rgba(20, 20, 20, 1);
    border: 1px solid rgba(38, 38, 38, 1);
    border-radius: 8px;
  }
  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    gap: 5px;
    .MuiListItemText-root {
      &::before {
        background-image: url(${Arrow});
        content: "";
        width: 21px;
        height: 21px;
        position: absolute;
        top: 15px;
        right: 21px;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
    }
    &[aria-expanded="true"] {
      .MuiListItemText-root {
        &::before {
          transform: rotate(180deg) !important;
        }
      }
    }
  }

  .MuiTypography-root {
    color: rgba(153, 153, 153, 1);
    font-family: "Urbanist" !important;
  }
  .MuiListItemIcon-root {
    justify-content: space-around;
    align-items: center;
    min-width: 39px;
  }
  .MuiSvgIcon-fontSizeMedium {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
  }
`;
