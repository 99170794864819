import React from "react";
import Footer from "../landing/components/footer";
import styled from "styled-components";
import Nav from "../landing/components/nav-page/Nav";
import BookingCard from "./components/BookingCard";
import { Col, Row } from "react-bootstrap";
import data from "../bookingsession/components/BookingCard/data";
import HeadingLabel from "../../components/HeadingLabel";
import PsSearchfield from "../../components/PsSearchfield";
import { PsDropdown } from "../../components/PsDropdown";
import {
  ProgramIcon,
  PasswordHideIcon,
  AgeIcon,
  DayIcon,
  TermIcon,
  LocationIcon,
} from "../../assets/img/icon/index";

const BookingSession = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const dummyData = [
    { id: 1, name: "John", icon: <PasswordHideIcon />, children: null },
    { id: 2, name: "Mathew", icon: <PasswordHideIcon />, children: null },
    { id: 3, name: "Snow", icon: <PasswordHideIcon />, children: null },
  ];
  return (
    <Wrapper>
      <HeadingLabel title="Booking" boldText="Sessions" />
      <SearchWrapper>
        <PsSearchfield
          placeholder={"Search For An Event"}
          value={searchValue}
          handleChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </SearchWrapper>
      <DropdownWrapper>
        <PsDropdown
          data={dummyData}
          placeholder="Location"
          value=""
          placeholderIcon={<LocationIcon />}
        />
        <PsDropdown
          data={dummyData}
          placeholder="Select Program"
          value=""
          placeholderIcon={<ProgramIcon />}
        />
        <PsDropdown
          data={dummyData}
          placeholder="Select Age Group"
          value=""
          placeholderIcon={<AgeIcon />}
        />
        <PsDropdown
          data={dummyData}
          placeholder="Term 3"
          value=""
          placeholderIcon={<TermIcon />}
        />
        <PsDropdown
          data={dummyData}
          placeholder="Select Day"
          value=""
          placeholderIcon={<DayIcon />}
        />
      </DropdownWrapper>
      <Row className="m-0">
        {data.map((item, index) => {
          return (
            <Col className="mb-5" xs={12} sm={6} md={4} key={index}>
              <BookingCard item={item} />
            </Col>
          );
        })}
      </Row>
    </Wrapper>
  );
};

export default BookingSession;
const Wrapper = styled.div`
  background: #181818;
  padding: 120px 10% 5% 10%;
  font-family: "Poppins";
`;
const SearchWrapper = styled.div`
  width: 80%;
  margin: 0 auto 20px auto;
`;
const DropdownWrapper = styled.div`
  padding-bottom: 30px;
  width: 95%;
  margin: 0 auto 30px auto;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1366px) and (max-width: 1559px) {
    justify-content: space-between;
  }
  .dropdown-wrapper {
    flex: 0 1 calc(20% - 15px);
    @media (max-width: 1365px) {
      flex-basis: calc(33.3% - 15px);
      padding-bottom: 10px;
    }
    @media (min-width: 1366px) and (max-width: 1559px) {
      flex-basis: calc(25% - 15px);
      padding-bottom: 10px;
    }
  }
`;
