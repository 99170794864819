import Img2 from "../journey/assets/images/1.png";
import Img3 from "../journey/assets/images/2.png";

const data = [
  {
    name: "Seth Amoafo",
    image: Img2,
    description: "I was supposed to be a lawyer..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
  {
    name: "Kusloom Faizn",
    image: Img3,
    description: "My story is one about angels..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
  {
    name: "Seth Amoafo",
    image: Img3,
    description: "I was supposed to be a lawyer..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
  {
    name: "Seth Amoafo",
    image: Img2,
    description: "I was supposed to be a lawyer..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
  {
    name: "Kusloom Faizn",
    image: Img2,
    description: "My story is one about angels..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
  {
    name: "Seth Amoafo",
    image: Img3,
    description: "I was supposed to be a lawyer..",
    details:
      "My Ghanaian parents held a few non-negotiable expectations for me: educate myself enthusiastically, work tirelessly, and be kind. I only needed to look to them to understand the importance of those three tenets.....",
    dateTime: "March 17, 2024 • 5 minutes ago",
  },
];
export default data;
