import React from "react";
import styled from "styled-components";
import GradientText from "../../../../components/gradientText";
import Bg from "../about/assets/images/1.png";
const About = () => {
  return (
    <Wrapper>
      <GradientText title={"Pass is for you if"} />
      <ContentWrapper>
        <p>
          <span>#1</span> You Believe in the power of purpose
        </p>
        <p>
          <span>#2</span>
          You Believe in the in the value of sport to develop a community
          cohesion
        </p>
        <p>
          <span>#3</span> You Believe sport can be used for greater good
        </p>
      </ContentWrapper>
    </Wrapper>
  );
};

export default About;
const Wrapper = styled.div`
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #030303;
  height: 100vh;
  color: #fff;
  font-family: "Poppins";
  padding: 5% 10% 5% 10%;
  @media (min-width: 100px) and (max-width: 1199px) {
    padding: 5% 6% 5% 6%;
  }
  h3 {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.5px;
  }
`;
const ContentWrapper = styled.div`
  padding: 15px 0 0 0;
  flex-direction: column;
  gap: 20px;
  p {
    font-size: 2vw;
    line-height: 60px;
    letter-spacing: -0.5px;
    span {
      margin-right: 18px;
    }
  }
`;
