import React from "react";
import styled from "styled-components";

const WhoAreWeCard = ({ ...props }) => {
  const {
    url,
    topIcon,
    gradientText,
    gradientTextEnd,
    spiralImg,
    text,
    footerText,
  } = props;
  return (
    <CardWrapper className="who-we-are-card">
      <TopImgWrapper>
        <img src={topIcon} alt="" className="img-fluid" />
      </TopImgWrapper>
      <CardSubWrapper>
        <CardTextWrapper>
          {!gradientTextEnd && (
            <GradientLabel className="gradientTextTop">
              {gradientText}
            </GradientLabel>
          )}
          <TextLabelWrapper>
            <TextLabel>
              {text}
              {gradientTextEnd && (
                <GradientLabel className="gradientTextEnd">
                  {gradientText}
                </GradientLabel>
              )}
            </TextLabel>
          </TextLabelWrapper>
          <FooterTextLabel> {footerText}</FooterTextLabel>
        </CardTextWrapper>
        <CardImgWrapper>
          <img src={url} alt="" className="img-fluid card-img" />
        </CardImgWrapper>
      </CardSubWrapper>
      <SpiralUiImg src={spiralImg} />
    </CardWrapper>
  );
};

export default WhoAreWeCard;

const CardWrapper = styled.div`
  position: relative;
  background: #000000;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 400px;
  border-radius: 5px;
  font-family: "Poppins";
  overflow: hidden;
  &:hover {
    background: #1c1c1c;
  }
  img {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
`;
const CardSubWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 35px 0px 35px 30px;
  height: 100%;
`;
const CardTextWrapper = styled.div`
  padding-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const GradientLabel = styled.span`
  font-size: clamp(25px, 2vw, 25px);
  font-weight: bold;
  background: linear-gradient(90deg, #ff00a4, #ffc107, #00c6ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &.gradientTextEnd {
    font-size: 35px;
    font-weight: 700;
    padding: 0 0 0 10px;
  }
  .gradientTextTop {
    font-size: 35px;
    font-weight: 700;
  }
  @media (min-width: 100px) and (max-width: 991px) {
    font-size: clamp(18px, 2vw, 18px);
    &.gradientTextEnd,
    &.gradientTextTop {
      font-size: 23px;
    }
  }
  @media (min-width: 992px) and (max-width: 1319px) {
    font-size: clamp(21px, 2vw, 21px);
    &.gradientTextEnd,
    &.gradientTextTop {
      font-size: 26px;
    }
  }
  @media (min-width: 1320px) and (max-width: 1559px) {
    font-size: clamp(23px, 2vw, 23px);
    &.gradientTextEnd,
    &.gradientTextTop {
      font-size: 30px;
    }
  }
`;
const TextLabelWrapper = styled.div`
  max-width: 232px;
  text-align: start;
  text-wrap: wrap;
`;
const TextLabel = styled.p`
  font-weight: 400;
  font-size: clamp(25px, 2vw, 25px);
  margin-bottom: unset !important;
  @media (min-width: 100px) and (max-width: 991px) {
    font-size: clamp(18px, 2vw, 18px);
  }
  @media (min-width: 992px) and (max-width: 1319px) {
    font-size: clamp(21px, 2vw, 21px);
  }
  @media (min-width: 1320px) and (max-width: 1559px) {
    font-size: clamp(23px, 2vw, 23px);
  }
`;
const FooterTextLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #ffff;
  position: absolute;
  bottom: 0px;
`;
const CardImgWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: end;
  justify-content: end;
  z-index: 2;
  position: relative;
`;
const TopImgWrapper = styled.div`
  width: fit-content;
  position: absolute;
  left: 25px;
  top: 30px;
`;
const SpiralUiImg = styled.img`
  width: fit-content;
  position: absolute;
  right: -100px;
  bottom: -65px;
`;
