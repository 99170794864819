import React from "react";
import CustomButton from "../../../../components/customButton";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Player from "./assets/img/player-black-white.svg";
import NextSessionIcon from "./assets/img/football.png";
import Waves from "../../../../assets/img/waves.svg";
import GradientText from "../../../../components/gradientText";
import { useNavigateToRoute } from "../../../../utils/common";
const BookingSessionBlock = () => {
  const redirect = useNavigateToRoute();
  return (
    <Wrapper>
      <ContainerWrapper>
        <ContainerSubWrapper>
          <Row className="m-0 w-100">
            <Col className="p-0" xs={12} sm={12} md={5}>
              <GradientText
                title={"Book"}
                gradientTitle={"Sessions"}
                description={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                }
              />
            </Col>
            <Col className="p-0 " xs={12} sm={12} md={7}>
              <Row className="m-0 p-0 w-100">
                <Col className="p-0 mb-4" xs={12}>
                  <CardWrapper className="CardWrapper">
                    <CardSubWrapper>
                      <CardTextWrapper>
                        <DateLabelSpan>26 Mar 2024</DateLabelSpan>
                        <h5>
                          NETBUSTERS (7 TO 9 YEARS) | BOTH BOYS AND GIRLS{" "}
                        </h5>

                        <CardSubLabelBlock className="CardSubLabelBlock">
                          <p className="m-0">
                            BSAK (British School Al Khubairat)
                          </p>
                          <SpeacialLabelBlock>
                            Location:
                            <SpeacialLabel>
                              INDOOR Dome At Al Hudayriyat Island (321 Sports)
                            </SpeacialLabel>
                          </SpeacialLabelBlock>
                          <p className="m-0">
                            Foundation (Recreational) Program
                          </p>
                          <p className="m-0">
                            Apr 21 2024 - Jun 30 2024 ( 11 weeks )
                          </p>
                          <p className="m-0">
                            01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )
                          </p>
                        </CardSubLabelBlock>
                        <NextSessionBlock className="NextSessionBlock">
                          <img
                            src={NextSessionIcon}
                            alt=""
                            className="img-fluid"
                          />
                          Next session starts in 26 DAY(s)
                        </NextSessionBlock>
                      </CardTextWrapper>
                    </CardSubWrapper>
                    <CardImageWrapper className="CardImageWrapper">
                      <img src={Player} alt="" className="img-fluid" />
                      <CradBtnWrapper className="CradBtnWrapper">
                        <CustomButton
                          onClick={() => {
                            redirect("/login");
                          }}
                          label1="Book now"
                          btnType="square"
                        ></CustomButton>
                      </CradBtnWrapper>
                    </CardImageWrapper>
                  </CardWrapper>
                </Col>
                <Col className="p-0 mb-4" xs={12}>
                  <CardWrapper className="CardWrapper">
                    <CardSubWrapper>
                      <CardTextWrapper>
                        <DateLabelSpan>26 Mar 2024</DateLabelSpan>

                        <h5>
                          NETBUSTERS (7 TO 9 YEARS) | BOTH BOYS AND GIRLS{" "}
                        </h5>

                        <CardSubLabelBlock className="CardSubLabelBlock">
                          <p className="m-0">
                            BSAK (British School Al Khubairat)
                          </p>
                          <SpeacialLabelBlock>
                            Location:
                            <SpeacialLabel>
                              INDOOR Dome At Al Hudayriyat Island (321 Sports)
                            </SpeacialLabel>
                          </SpeacialLabelBlock>
                          <p className="m-0">
                            Foundation (Recreational) Program
                          </p>
                          <p className="m-0">
                            Apr 21 2024 - Jun 30 2024 ( 11 weeks )
                          </p>
                          <p className="m-0">
                            01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )
                          </p>
                        </CardSubLabelBlock>
                        <NextSessionBlock className="NextSessionBlock">
                          <img
                            src={NextSessionIcon}
                            alt=""
                            className="img-fluid"
                          />
                          Next session starts in 26 DAY(s)
                        </NextSessionBlock>
                      </CardTextWrapper>
                    </CardSubWrapper>
                    <CardImageWrapper className="CardImageWrapper">
                      <img src={Player} alt="" className="img-fluid" />
                      <CradBtnWrapper className="CradBtnWrapper">
                        <CustomButton
                          onClick={() => {
                            redirect("/login");
                          }}
                          label1="Book now"
                          btnType="square"
                        ></CustomButton>
                      </CradBtnWrapper>
                    </CardImageWrapper>
                  </CardWrapper>
                </Col>
                <Col className="p-0 mb-4" xs={12}>
                  <CardWrapper className="CardWrapper">
                    <CardSubWrapper>
                      <CardTextWrapper>
                        <DateLabelSpan>26 Mar 2024</DateLabelSpan>

                        <h5>
                          NETBUSTERS (7 TO 9 YEARS) | BOTH BOYS AND GIRLS{" "}
                        </h5>

                        <CardSubLabelBlock className="CardSubLabelBlock">
                          <p className="m-0">
                            BSAK (British School Al Khubairat)
                          </p>
                          <SpeacialLabelBlock>
                            Location:
                            <SpeacialLabel>
                              INDOOR Dome At Al Hudayriyat Island (321 Sports)
                            </SpeacialLabel>
                          </SpeacialLabelBlock>
                          <p className="m-0">
                            Foundation (Recreational) Program
                          </p>
                          <p className="m-0">
                            Apr 21 2024 - Jun 30 2024 ( 11 weeks )
                          </p>
                          <p className="m-0">
                            01:00 Hours - 87.00 AED ( 09:00 AM - 10:00 AM )
                          </p>
                        </CardSubLabelBlock>
                        <NextSessionBlock className="NextSessionBlock">
                          <img
                            src={NextSessionIcon}
                            alt=""
                            className="img-fluid"
                          />
                          Next session starts in 26 DAY(s)
                        </NextSessionBlock>
                      </CardTextWrapper>
                    </CardSubWrapper>
                    <CardImageWrapper className="CardImageWrapper">
                      <img src={Player} alt="" className="img-fluid" />
                      <CradBtnWrapper className="CradBtnWrapper">
                        <CustomButton
                          onClick={() => {
                            redirect("/login");
                          }}
                          label1="Book now"
                          btnType="square"
                        ></CustomButton>
                      </CradBtnWrapper>
                    </CardImageWrapper>
                  </CardWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </Container> */}
        </ContainerSubWrapper>
      </ContainerWrapper>
      <ImageWrapper>
        <img src={Waves} alt="" className="img-fluid" />
      </ImageWrapper>
    </Wrapper>
  );
};

export default BookingSessionBlock;
const Wrapper = styled.div`
  min-height: calc(100vh + 250px);
  background: #181818;
  position: relative;
  font-family: "Poppins";
  padding: 5% 10% 5% 10%;
  @media (min-width: 100px) and (max-width: 1199px) {
    padding: 5% 6% 5% 6%;
  }
`;
const ContainerWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;
const ContainerSubWrapper = styled.div`
  .gradient-content {
    position: sticky;
    top: 150px;
    padding-bottom: 300px;
  }
`;
const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
`;
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  margin: 0 0 22px 0;
  background: #000000;
  position: relative;
  height: 100%;
  &:hover {
    background: #252525;
  }
  &::before {
    content: "";
    height: 3px;
    width: 100%;
    position: absolute;
    top: -5px;
    background: linear-gradient(
      130deg,
      #a85cde,
      #ef5bb6,
      #fcd54d,
      #5fcab4,
      #0083ff
    );
  }
  @media (min-width: 320px) and (max-width: 720px) {
    flex-direction: column;
    .CardImageWrapper {
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
        max-height: 250px;
        object-position: top;
      }
      .CradBtnWrapper {
        padding: 0;
      }
    }
    .NextSessionBlock {
      position: unset;
    }
  }
`;
const CardSubWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 25px;
`;
const CardTextWrapper = styled.div`
  text-align: start;
  color: #ffffff;
  font-family: "Poppins";
  height: 100%;
  position: relative;
  h5 {
    font-weight: 400;
    font-size: 22px;
    font-size: clamp(18px, 22px, 22px);
  }
`;
const CradBtnWrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 10px 20px 10px;
  left: 0;
  right: 0;
  button {
    min-height: 45px !important;
    &:hover {
      background: #fff;
      color: #000000;
      img {
        filter: invert(1);
      }
    }
  }
`;
const CardImageWrapper = styled.div`
  position: relative;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
  }
`;
const SpeacialLabelBlock = styled.div``;
const DateLabelSpan = styled.span`
  margin: 0 0 15px 0;
  display: block;
  width: fit-content;
  padding: 5px 8px;
  border-radius: 6px;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-family: "Poppins";
  font-size: 14px;
`;
const CardSubLabelBlock = styled.div`
  margin: 0 0 25px 0;
  p {
    padding: 5px 0 0 0;
  }
`;
const SpeacialLabel = styled.span`
  padding: 3px 5px;
  color: #f39100;
  font-size: 16px;
  margin: 0 5px 0 0;
`;
const NextSessionBlock = styled.div`
  color: #ffffff;
  padding: 5px 8px;
  border: 1px solid #fff;
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  img {
    width: 20px;
    margin: 0 10px 0 0;
  }
`;
