import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";

export const PsCheckbox = ({
  handleChange,
  value,
  label,
  defaultChecked,
  checked,
  textColor,
  borderColor,
  checkedColor,
}) => {
  return (
    <div>
      <FormControlLabel
        label={label}
        x
        sx={{ color: textColor ? textColor : "#ffffff" }}
        control={
          <CustomCheckbox
            sx={{
              color: borderColor ? borderColor : "rgb(255, 255, 255, 0.4)",
              "&.Mui-checked": {
                color: checkedColor ? checkedColor : "rgba(182, 147, 240, 1)",
              },
            }}
            defaultChecked={defaultChecked}
            value={value}
            onChange={handleChange}
            checked={checked}
          />
        }
      />
    </div>
  );
};
const CustomCheckbox = styled(Checkbox)`
  &.MuiButtonBase-root {
    padding: 0px 5px 0 9px;
    .MuiTypography-root {
      font-family: "Poppins";
      font-size: 14px;
    }
  }
`;
