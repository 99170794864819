import "./App.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing";
import BookingSession from "./pages/bookingsession";
import TestPage from "./pages/testPage";
import UpcomingEvents from "./pages/upcomingEvents";
import HomeLayout from "./components/HomeLayout";
import AboutUs from "./pages/aboutus";
import Store from "./pages/store";
import { LoginPage } from "./pages/login";
import NotFound from "./pages/notfound";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route element={<HomeLayout />}>
          <Route path="/booking-session" element={<BookingSession />} />
          <Route path="/components" element={<TestPage />} />
          <Route path="/:pathRoute" element={<LoginPage />} />
          <Route path="/upcoming-events" element={<UpcomingEvents />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/store" element={<Store />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
