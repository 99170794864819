import React from "react";
import styled from "styled-components";
import data from "./data";
import LogoCard from "../../../../components/logocard";
import { Col, Row } from "react-bootstrap";
const OurPartners = () => {
  return (
    <Wrapper>
      <h3>
        Our <span>partners</span>
      </h3>
      <Row className="row-custom">
        {data.map((item, index) => {
          return (
            <Col
              className="p-0 mb-1 custom-col"
              xs={12}
              sm={12}
              md={2}
              key={index}
            >
              <LogoCard item={item} />
            </Col>
          );
        })}
      </Row>
    </Wrapper>
  );
};

export default OurPartners;

const Wrapper = styled.div`
.row-custom{
  padding: 0;
}
  h3 {
    color: #000;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
    span {
      font-weight: 800;
    }
  }
  background-color: #ffffff;
  font-family: "Poppins";
  padding: 60px 10%;
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding: 60px 6% 60px 6%;
  }
  .custom-col {
    &.col-md-2 {
      flex: 0 0 auto;
      width: 20%;
      padding: 7px !important;
      margin-bottom: unset !important;
    }
  }
`;

