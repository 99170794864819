import React, { useEffect } from "react";
import styled from "styled-components";
import { PsButton } from "../PsButton";
import { PsTextField } from "../PsTextField";
import Divider from "@mui/material/Divider";
import { GoogleLogin } from "react-google-login";
import { LabelMedium, PageHeadingLabel } from "../PsLabel";
import { PsCheckbox } from "../PsCheckbox";
import { useParams } from "react-router-dom";
import { useNavigateToRoute } from "../../utils/common";

export const PsLoginCard = ({ className, formNumber, setFormNumber }) => {
  let { pathRoute } = useParams();
  const redirect = useNavigateToRoute();
  const loginButtonBgColor =
    "linear-gradient(45deg, rgba(98, 142, 255, 1) 0%, rgba(135, 64, 205, 1) 50%, rgba(88, 4, 117, 1) 100%)";
  const signupButtonBgColor =
    "linear-gradient(45deg, rgba(46, 76, 238, 1) 0%, rgba(34, 30, 191, 1) 50%, rgba(4, 15, 117, 1) 100%)";
  const fogetButtonBgColor =
    "linear-gradient(45deg, rgba(233, 72, 197, 1) 0%, rgba(205, 64, 123, 1) 50%, rgba(117, 4, 45, 1) 100%)";

  const responseGoogle = (response) => {
    // Handle the Google sign-in response, e.g., send to backend for authentication
  };
  const handleloginSignupClick = () => {
    if (formNumber === 0 || formNumber === 2) {
      redirect("/signup");
      setFormNumber(1);
    } else if (formNumber === 1) {
      redirect("/login");
      setFormNumber(0);
    } else {
      redirect("/login");
      setFormNumber(0);
    }
  };
  const handleForgotPassword = () => {
    redirect("/forgot-password");
    setFormNumber(2);
  };
  useEffect(() => {
    if (pathRoute === "login") {
      setFormNumber(0);
    } else if (pathRoute === "signup") {
      setFormNumber(1);
    } else {
      setFormNumber(2);
    }
  }, [pathRoute]);
  return (
    <LoginCardWrapper className={className}>
      <TopWrapper>
        <PageHeadingLabel
          color="rgba(255, 255, 255, 1)"
          className="card-title"
          size="28px"
        >
          {formNumber === 0
            ? "Login"
            : formNumber === 1
            ? "Sign Up"
            : formNumber === 2
            ? "Forgot Password ?"
            : ""}
        </PageHeadingLabel>
        <LabelMedium color="rgba(255, 255, 255, 1)" className="card-subtitle">
          {formNumber === 0
            ? "Glad you’re back.!"
            : formNumber === 1
            ? "Just some details to get you in.!"
            : formNumber === 2
            ? "Please enter you’re email"
            : ""}
        </LabelMedium>
        {(formNumber === 0 || formNumber === 1 || formNumber === 2) && (
          <>
            {(formNumber === 0 || formNumber === 1) && (
              <>
                <ContentWrapper>
                  <PsTextField placeholder="Username" />
                  {formNumber === 1 && (
                    <PsTextField placeholder={"Email/Phone"} type="number" />
                  )}
                  <PsTextField placeholder="Password" type="password" />
                  {formNumber === 1 && (
                    <PsTextField
                      placeholder="Confirm Password"
                      type="password"
                    />
                  )}
                </ContentWrapper>
                {formNumber === 0 && <PsCheckbox label={"Remember me"} />}
              </>
            )}
            {formNumber === 2 && (
              <PsTextField placeholder="example@mail.com" type="email" />
            )}
            <PsButton
              label={
                formNumber === 0
                  ? "Login"
                  : formNumber === 1
                  ? "Signup"
                  : formNumber === 2
                  ? "Reset Password"
                  : ""
              }
              bgColor={
                formNumber === 0
                  ? loginButtonBgColor
                  : formNumber === 1
                  ? signupButtonBgColor
                  : formNumber === 2
                  ? fogetButtonBgColor
                  : ""
              }
            />
            {formNumber === 0 && (
              <LabelMedium
                className="Forgot-password"
                color="rgba(255, 255, 255, 1)"
                onClick={handleForgotPassword}
              >
                Forgot password ?
              </LabelMedium>
            )}
          </>
        )}
      </TopWrapper>
      {(formNumber === 0 || formNumber === 1) && (
        <>
          <StyledDivider className="section-divider">Or</StyledDivider>
          <GoogleSigninWrapper>
            <GoogleLogin
              clientId="YOUR_CLIENT_ID"
              buttonText="Sign in with"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </GoogleSigninWrapper>
        </>
      )}
      <LabelMedium
        color="rgba(255, 255, 255, 1)"
        className={formNumber === 0 ? "account" : "no-account"}
        onClick={handleloginSignupClick}
      >
        {formNumber === 0 || formNumber === 2 ? (
          <span style={{ fontSize: "12px" }}>
            Don’t have an account? <b>Signup</b>
          </span>
        ) : formNumber === 1 ? (
          "Already Registered? Login"
        ) : (
          ""
        )}
      </LabelMedium>
    </LoginCardWrapper>
  );
};
const LoginCardWrapper = styled.div`
  border: 1px solid rgba(191, 191, 191, 0.31);
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
  min-height: 600px;
  height: 100%;
  padding: 80px 30px 10px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .account {
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin: 60px 0 20px;
  }
  .no-account {
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin: 60px 0 40px;
  }
`;
const StyledDivider = styled(Divider)`
  &::before {
    border-top: thin solid rgba(77, 77, 77, 1) !important;
  }
  &::after {
    border-top: thin solid rgba(77, 77, 77, 1) !important;
  }

  span {
    color: rgba(77, 77, 77, 1) !important;
  }
`;
const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .Forgot-password {
    text-align: center;
    margin: 10px 0px 30px;
    cursor: pointer;
  }
  .custom-btn-wrapper {
    padding-top: 15px;
  }
  .card-title {
    padding: 0 0 5px 0;
  }
  .card-subtitle {
    padding: 0 0 10px 0;
    letter-spacing: 0.5px;
  }
`;
const ContentWrapper = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  input {
    border-width: 0.5px !important;
  }
`;
const GoogleSigninWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
  button {
    flex-direction: row-reverse;
    background-color: transparent !important;
    color: #ffffff !important;
    opacity: 1 !important;
    box-shadow: none !important;
    div {
      background-color: transparent !important;
      padding: 0px !important;
      svg {
        width: 24px !important;
      }
    }
    span {
      font-size: 13px;
    }
  }
`;
