import React from "react";
import styled, { css } from "styled-components";
import { LabelNormal } from "../PsLabel";
import { PasswordHideIcon } from "../../assets/img/icon/index";

export const PsTextField = ({
  placeholder,
  label,
  value,
  disabled,
  className,
  onChange,
  validator,
  validations,
  labelColor,
  type,
  bgColor,
  borderColor,
  textColor,
  autoComplete,
}) => {
  const simpleValidator = React.useRef(validator);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleChange = (e) => {
    onChange?.(e);
    simpleValidator?.current?.showMessageFor(label ? label : "field");
  };
  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <Wrapper className={className}>
      {label && (
        <LabelWrapper>
          <LabelNormal weight="500" color={labelColor ? labelColor : "#ffffff"}>
            {label}
          </LabelNormal>
        </LabelWrapper>
      )}
      <InputWrapper>
        {type === "password" && (
          <>
            <PasswordToggle onClick={handleTogglePassword}>
              {showPassword ? <PasswordHideIcon /> : <PasswordHideIcon />}
            </PasswordToggle>
          </>
        )}
        <input
          className={disabled ? "active" : "not-active"}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          name={label ? label : "field"}
          type={
            showPassword ? "text" : type === "password" ? "password" : "text"
          }
          bgColor={bgColor}
          borderColor={borderColor}
          textColor={textColor}
          autoComplete={autoComplete ? autoComplete : "off"}
          style={{
            background: bgColor ? bgColor : "#000000",
            color: textColor ? textColor : "#ffffff",
            border: borderColor
              ? "1px solid " + borderColor
              : "1px solid rgb(255, 255, 255, 0.4)",
          }}
        />
        {simpleValidator?.current?.message(
          label ? label : "field",
          value,
          validations ?? ""
        )}
      </InputWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .srv-validation-message {
    color: rgb(244 67 54);
    font-size: 12px;
    display: inline-block;
    font-family: "Poppins";
  }
  input {
    color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
    background: ${(props) => props.bgColor || "#000000"};
    border: 1px solid ${(props) => props.borderColor || "#ffffff"};
    border-radius: 12px;
    width: 100%;
    height: 44px;
    padding: 5px 10px;
    &::placeholder {
      color: #ffffff;
    }
    &:focus-visible {
      outline: unset !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #ffffff !important;
    -webkit-background-clip: text !important;
  }
`;
const LabelWrapper = styled.div`
  margin-bottom: 8px;
`;
const InputWrapper = styled.div`
  width: 100%;
  height: 45px;
  position: relative;
`;
const PasswordToggle = styled.span`
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 10px;
  z-index: 1;
  img {
    width: 22px;
    height: 22px;
  }
`;
