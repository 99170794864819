import styled from "styled-components";

export function PsLabel({ color, weight, children, className, onclick ,size}) {
  return (
    <LabelMedium
      onClick={onclick}
      color={color}
      weight={weight}
      className={className}
      size={size}
    >
      {children}
    </LabelMedium>
  );
}

export const PageHeadingLabel = styled.label`
  font-weight: ${(props) => props.weight || "600"};
  font-size: ${(props) => props.size || "24px"};
  color: ${(props) => props.color};
  text-transform: capitalize;
  line-height: unset;
  font-family: "Poppins";
`;
export const LabelLarge = styled.label`
  font-size: 16px;
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color};
  font-family: "Poppins";
`;
export const LabelMedium = styled.label`
  font-size: 14px;
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color};
  padding-bottom: 5px;
  font-family: "Poppins";
`;
export const LabelNormal = styled.label`
  font-size: 12px;
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color};
  font-family: "Poppins";
`;

export const LabelSmall = styled.label`
  font-size: 10px;
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color};
  font-family: "Poppins";
`;
export const LabelExtraLarge = styled.label`
  font-size: 18px;
  font-weight: ${(props) => props.weight || "800"};
  color: ${(props) => props.color};
  font-family: "Poppins";
`;
