import React from "react";
import Logo from "../../assets/images/logo.png";
import about from "./assets/about.svg";
import brandBooking from "./assets/brandBooking.svg";
import campsite from "./assets/campsite.svg";
import cupStar from "./assets/cupStar.svg";
import events from "./assets/events.svg";
import notifications from "./assets/notifications.svg";
import store from "./assets/store.svg";
import loginIcon from "./assets/ic_round-login.png";
import styled from "styled-components";
import { LabelLarge } from "../../../../components/PsLabel";
import { useNavigateToRoute } from "../../../../utils/common";
import { NavLink, useParams } from "react-router-dom";
const Nav = () => {
  const redirect = useNavigateToRoute();
  let { pathRoute } = useParams();
  return (
    <Wrapper
      className="fixed top-0 left-0 right-0 z-10 backdrop-blur-[5px] bg-[#0000004d] pt-[0] pr-[5%] pb-[0] pl-[5%]"
      style={{ fontFamily: "Poppins" }}
    >
      <div className="flex justify-between align-middle max-w-[100%] m-auto">
        <div className="flex justify-center items-center">
          <img
            className="logo-img"
            style={{ cursor: "pointer" }}
            src={Logo}
            alt="Dalfin Logo"
            onClick={() => {
              redirect("/");
            }}
          />
          {pathRoute ? (
            ""
          ) : (
            <MemberLoginWrapper
              onClick={() => {
                redirect("/login");
              }}
            >
              <img src={loginIcon} alt="" />
              <LabelLarge color="#ffffff">Member login</LabelLarge>
            </MemberLoginWrapper>
          )}
        </div>
        <div className="nav-wrapper flex align-middle w-[calc(100%-200px)] justify-end">
          <ul className="m-0 w-100 flex justify-between max-w-[1250px] p-0 wrapper-ul">
            <li className="inline-block font-semibold">
              <NavLink
                className="testttttt py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
                to={"/"}
              >
                <div className="flex items-center">
                  <img src={notifications} alt="" />
                  <span className="ml-2">Home</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/about-us"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={about} alt="" />
                  <span className="ml-2">About us</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/booking-session"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={brandBooking} alt="" />
                  <span className="ml-2">Book Sessions</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/upcoming-events"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={events} alt="" />

                  <span className="ml-2">Events</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/not-found"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={campsite} alt="" />

                  <span className="ml-2">Spring Camps</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/store"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={store} alt="" />
                  <span className="ml-2">Store</span>
                </div>
              </NavLink>
            </li>
            <li className="inline-block font-semibold">
              <NavLink
                to={"/not-found"}
                className="py-[25px] px-[10px] block no-underline text-[#ffffff] capitalize"
              >
                <div className="flex items-center">
                  <img src={cupStar} alt="" />
                  <span className="ml-2">Ramadan Cup</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};
export default Nav;
const Wrapper = styled.div`
  .wrapper-ul {
    li {
      font-weight: 400 !important;
    }
    a {
      cursor: pointer;
      span {
        font-size: 14px;
        @media (min-width: 992px) and (max-width: 1259px) {
          font-size: 12px;
        }
      }
      &.active {
        background: #ffffff36;
        box-shadow: inset 0px -5px 0px 0px #ffffff7d;
      }
    }
    img {
      width: 23px;
      height: 23px;
      @media (min-width: 992px) and (max-width: 1259px) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .nav-wrapper {
    padding-left: 15px;
  }
  .logo-img {
    @media (min-width: 1100px) and (max-width: 1299px) {
      width: 110px;
    }
    @media (min-width: 992px) and (max-width: 1099px) {
      width: 100px;
      height: 40px;
    }
  }
`;
const MemberLoginWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  margin-top: -2px;
  img {
    @media (min-width: 992px) and (max-width: 1259px) {
      width: 18px;
      height: 18px;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    width: 135px;
    @media (min-width: 992px) and (max-width: 1259px) {
      font-size: 12px;
    }
  }
`;
