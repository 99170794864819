import React from "react";
import styled from "styled-components";
const VehicleCard = ({ item }) => {
  return (
    <Wrapper>
      <WrapperRow>
        <ImgWrapper>
          <img src={item.image} alt="" />
        </ImgWrapper>
        <p>{item.title}</p>
      </WrapperRow>
    </Wrapper>
  );
};

export default VehicleCard;

const WrapperRow = styled.div``;
const Wrapper = styled.div`
  text-align: center;
  background: #1e1e1e;
  color: #fff;
  height: 100%;
  margin-right: 4px;
  padding: 30px 25px 30px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #0c0c0c;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.8;
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 20px 0;
`;
