import { useNavigate } from "react-router-dom";

export function useNavigateToRoute() {
  const navigate = useNavigate();

  function redirect(redirectPath) {
    navigate(redirectPath);
  }
  return redirect;
}
