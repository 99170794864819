import React from "react";
import styled from "styled-components";
import { LabelExtraLarge, LabelMedium } from "../../../../components/PsLabel";
import TitleDescriptionLabel from "../../../../components/TitleDescriptionLabel";
import Banner from "../../assets/images/banner.png";
import InnerButton from "../../../../components/InnerButton";
import ButtonImg from "../../assets/images/buttonicon.png";
const EventsDetail = () => {
  return (
    <Wrapper>
      <img src={Banner} alt="" />
      <TextWrapper>
        <div style={{ paddingBottom: "20px" }}>
          <LabelExtraLarge color="#ffffff">
            GOTHIA CUP SECOND INSTALMENT (INTERNATIONAL)
          </LabelExtraLarge>
          <LabelMedium color="#98989A">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </LabelMedium>
        </div>
        {/* <InnerButton title={"View Now"} image={ButtonImg} /> */}
      </TextWrapper>
      <LabelWrapper>
        <TitleDescriptionLabel title="Event Date" description="March 2024" />
        <TitleDescriptionLabel
          title="Category"
          description="Second Instalment"
        />
        <TitleDescriptionLabel title="Academy" description="Pass Abudhabi" />
      </LabelWrapper>
    </Wrapper>
  );
};

export default EventsDetail;
const Wrapper = styled.div`
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const TextWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  .button-block {
    width: 375px;
  }
  label:nth-child(2) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
