import React from "react";
import styled from "styled-components";
import data from "./components/data";
import { Col, Row } from "react-bootstrap";
import EventsCard from "./components/eventscard";
import GradientText from "../../components/gradientText";
import Logo from "./assets/images/1.png";
import { LabelMedium } from "../../components/PsLabel";
import EventsDetail from "./components/eventsdetail";
import HeadingLabel from "../../components/HeadingLabel";
const UpcomingEvents = () => {
  return (
    <Wrapper>
      <HeadingLabel title="Events" />
      <Row style={{ marginTop: "30px" }}>
        <Col xs={12} sm={12} md={6}>
          <Row className="h-100 items-center">
            <LogoWrapper>
              <img src={Logo} alt="" />
              <GradientText title={"Upcoming"} gradientTitle={"Events"} />
              <LabelMedium color="#98989A">
                Provides technical specifications and requirements for
                implementing quantum computing systems.
              </LabelMedium>
            </LogoWrapper>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <EventsDetail />
        </Col>
      </Row>
      <GridWrapper>
        <Row>
          {data.map((item, index) => {
            return (
              <Col
                className="styled-col events-col"
                xs={12}
                sm={12}
                md={4}
                key={index}
              >
                <EventsCard item={item} />
              </Col>
            );
          })}
        </Row>
      </GridWrapper>
    </Wrapper>
  );
};

export default UpcomingEvents;
const Wrapper = styled.div`
  background: #181818;
  font-family: "Poppins";
  padding: 120px 10% 5% 10%;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 120px 5% 5% 5%;
  }
  color: #ffffff;
  .gradient-content {
    padding-bottom: 10px;
    h3 {
      font-size: 30px;
      display: inline-grid;
      span {
        font-size: 35px;
        padding-top: 8px;
      }
    }
    p {
      display: none;
    }
  }
  .events-col {
    @media (min-width: 768px) and (max-width: 991px) {
      flex: 0 0 auto;
      width: 50%;
    }
  }
`;
const GridWrapper = styled.div`
  padding-top: 45px;
  img {
    border-radius: 12px;
    height: 270px;
  }
  .styled-col {
    padding-bottom: 25px;
  }
`;
const LogoWrapper = styled.div`
  width: 75%;
  img {
    padding-bottom: 30px;
  }
`;
