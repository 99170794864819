import React from "react";
import styled from "styled-components";
import {
  LabelMedium,
  LabelNormal,
  LabelSmall,
} from "../../../../components/PsLabel";
import RatingImg from "../../assets/images/rating.png";
import Favourite from "../../assets/images/favourite.png";
import View from "../../assets/images/view.png";
import { useNavigateToRoute } from "../../../../utils/common";

const ProductCard = ({ item }) => {
  //   const getRating = () => {
  //     const ratingImages = [];
  //     for (let i = 0; i < item.rating; i++) {
  //       ratingImages.push(<img key={i} src={RatingImg} />);
  //     }
  //     return ratingImages;
  //   };
  const redirect = useNavigateToRoute();
  const getRating = () => {
    return Array(item.rating)
      .fill()
      .map((_, index) => <img key={index} src={RatingImg} />);
  };

  return (
    <MainWrapper>
      <Wrapper>
        <ImageWrapper>
          <img className="product-img" src={item.image} alt="" />
          {item.isNew ? (
            <LabelSmall className="new-product">NEW</LabelSmall>
          ) : (
            ""
          )}
          <ActionWrapper>
            <div>
              <img src={Favourite} alt="" />
            </div>
            <div>
              <img src={View} alt="" />
            </div>
          </ActionWrapper>
          <CartWrapper
            className="cart-wrapper"
            onClick={() => {
              redirect("/login");
            }}
          >
            <LabelMedium>Add to Cart</LabelMedium>
          </CartWrapper>
        </ImageWrapper>
      </Wrapper>
      <ContentWrapper>
        <LabelMedium>Lorem ipsum dolor sit amet</LabelMedium>
      </ContentWrapper>
      <PurchaseWrapper>
        <LabelMedium color="#FF8A00">{item.price}</LabelMedium>
        <div className="d-flex">{getRating()}</div>
        <LabelSmall weight="bold" color="#8c8c8c">
          ({item.reviewCount})
        </LabelSmall>
      </PurchaseWrapper>
    </MainWrapper>
  );
};

export default ProductCard;
const MainWrapper = styled.div`
  color: #ffffff;
`;
const Wrapper = styled.div`
  background: #252525;
  border-radius: 4px;
  padding: 25px;
  position: relative;
  .product-img {
    height: 175px;
    width: 135px;
    object-fit: contain;
  }
  cursor: pointer;
  &:hover {
    .cart-wrapper {
      display: block;
    }
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .new-product {
    position: absolute;
    top: 15px;
    left: 15px;
    background: #5ad970;
    border-radius: 4px;
    padding: 5px 10px;
  }
`;
const ContentWrapper = styled.div`
  padding-top: 10px;
`;
const PurchaseWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  gap: 15px;
  align-items: center;
  img {
    width: 15px;
    height: 14px;
    object-fit: cover;
  }
  label {
    padding-bottom: 0px;
    padding-top: 2px;
  }
`;
const ActionWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    background: #424141;
    height: 32px;
    width: 32px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
`;
const CartWrapper = styled.div`
  display: none;
  position: absolute;
  background: linear-gradient(45deg, #406ae5 0%, #46e782 100%);
  width: 100%;
  text-align: center;
  padding: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0px;
  label {
    padding-bottom: 0px;
    cursor: pointer;
  }
`;
