import React from "react";
import styled from "styled-components";
import ErrorImg from "./assets/black.jpg";
import { PsButton } from "../../components/PsButton";
import { useNavigateToRoute } from "../../utils/common";
const NotFound = () => {
  const redirect = useNavigateToRoute();
  return (
    <Wrapper>
      <ContentWrapper>
        <h1>404</h1>
        <h6>It looks like you are lost...!</h6>
        <PsButton
          onClick={() => {
            redirect("/");
          }}
          label={"Back to Home"}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default NotFound;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #000000;
  position: relative;

  &::after {
    content: "";
    background-image: url(${ErrorImg});
    position: absolute;
    height: 100vh;
    width: 50%;
    background-repeat: no-repeat;
    right: 0;
  }
`;
const ContentWrapper = styled.div`
  color: #ffffff;
  font-family: "Poppins";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  h1 {
    font-size: 130px;
    font-weight: 800;
  }
  h6 {
    font-size: 30px;
    margin-top: 25px;
  }
  button {
    margin-top: 60px;
  }
`;
