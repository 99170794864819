import React from "react";
import styled from "styled-components";
import Nav from "../../pages/landing/components/nav-page/Nav";
import { Outlet, useParams } from "react-router-dom";
import Footer from "../../pages/landing/components/footer";

const HomeLayout = () => {
  let { pathRoute } = useParams();
  const FooterWrapper = pathRoute ? "" : <Footer />;
  return (
    <Wrapper>
      <Nav />
      <Outlet />
      {FooterWrapper}
    </Wrapper>
  );
};

export default HomeLayout;
const Wrapper = styled.div``;
