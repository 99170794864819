import React from "react";
import styled from "styled-components";

export const PsButton = ({
  className,
  wrapperClassName,
  disabled,
  label,
  svgIcon,
  src,
  onClick,
  bgColor,
  borderColor,
  textColor,
  alt,
  borderRadius,
}) => {
  const defaultButtonBgColor =
    "linear-gradient(45deg, rgba(98, 142, 255, 1) 0%, rgba(135, 64, 205, 1) 50%, rgba(88, 4, 117, 1) 100%)";
  return (
    <Wrapper className={`custom-btn-wrapper   ${wrapperClassName}`}>
      <button
        style={{
          background: bgColor ? bgColor : defaultButtonBgColor,
          color: textColor ? textColor : "#ffffff",
          border: borderColor ? "1px solid " + borderColor : "unset",
          borderRadius: borderRadius ? borderRadius : "12px",
        }}
        className={` ${className ?? ""}`}
        onClick={onClick}
        disabled={disabled}
        bgColor={bgColor}
        borderColor={borderColor}
        textColor={textColor}
      >
        {(svgIcon || src) && (
          <IconWrapper>
            {svgIcon ? (
              svgIcon
            ) : src ? (
              <img src={src} alt={alt ? alt : "icon"} />
            ) : (
              ""
            )}
          </IconWrapper>
        )}
        {label ?? ""}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${(props) => props.textColor};
  position: relative;
  &.square {
    button {
      border-radius: 0px;
      padding: 2px 20px;
      min-height: 40px;
      min-width: 125px;
      background: rgb(255 255 255 / 80%);
      color: #000000;
    }
  }
  button {
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    padding: 10px 15px;
    min-height: 45px;
    background: ${(props) =>
      props.bgColor ||
      "linear-gradient(45deg, rgba(98, 142, 255, 1) 0%, rgba(135, 64, 205, 1) 50%, rgba(88, 4, 117, 1) 100%)"};
    border-radius: 12px;
    color: ${(props) => props.textColor || "#ffffff"};
    border: 1px solid ${(props) => props.borderColor || "unset"};
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 19px;
  left: 20px;
  z-index: 1;
  img {
    width: 22px;
    height: 22px;
  }
`;
