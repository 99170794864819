import React from "react";
import styled from "styled-components";
import GradientText from "../../components/gradientText";
import { LabelMedium } from "../../components/PsLabel";
import BannerImg from "./assets/images/1.png";
import { Col, Row } from "react-bootstrap";
import GradientImg from "./assets/images/2.png";
import HeadingLabel from "../../components/HeadingLabel";
const AboutUs = () => {
  return (
    <Wrapper>
      <HeadingLabel title="About Us" />
      <img src={GradientImg} className="gradient-bg" alt="" />
      <GradientText title={"Our"} gradientTitle={"Journey"} />
      <Row>
        <Col
          className="mb-2"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          xs={12}
          sm={12}
          md={6}
        >
          <div style={{ width: "95%" }}>
            <LabelMedium color="#999999">
              At ProActive Soccer School (PASS Abu Dhabi), our PURPOSE is
              simple: Whether it's for a session or ten years, we want to use
              sport as a vehicle to instill values in young players that will
              help them develop to be the best version of themselves.
            </LabelMedium>
            <LabelMedium color="#999999">
              We believe we are good at what we do, we care about each and every
              individual across our programs and we employ the best human beings
              available. In turn, we want to help our young players develop what
              we believe are the core values of humanity and the SPIRIT of PASS.
              These values have been translated into languages which we feel
              represent the multicultural world we live in. Plus foreign
              languages always sound cool. These are:
            </LabelMedium>
            <LabelMedium color="#999999">
              <ul>
                <li>- Sporting - Virtus (Latin)</li>
                <li>- Pride - Bród (Irish)</li>
                <li>- Integrity - Alnazaha (Arabic)</li>
                <li>- Respect - Quantum (Latin)</li>
                <li>- Discipline - Disciplus (Latin)</li>
                <li>- Teamwork - Alfareeq (Arabic)</li>
              </ul>
            </LabelMedium>
            <LabelMedium color="#999999">
              At PASS we believe in our purpose of using football (and sport)
              for the general good. And this is even more powerful when the
              whole community is involved. Thank you for coming on this journey
              with us. #practicemakespermanent
            </LabelMedium>
          </div>
        </Col>
        <Col
          className="mb-2"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          xs={12}
          sm={12}
          md={6}
        >
          <img src={BannerImg} alt="" />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default AboutUs;
const Wrapper = styled.div`
  background: #181818;
  padding: 120px 10% 5% 10%;
  font-family: "Poppins";
  label {
    font-size: 13px;
    &:nth-child(1) {
      margin-top: 40px;
    }
    &:nth-child(2) {
      margin-top: 45px;
    }
    &:nth-child(3) {
      margin-top: 10px;
    }
    &:nth-child(4) {
      margin-top: 10px;
    }
    ul {
      padding-left: 0px !important;
      margin-bottom: 0px !important;
    }
  }
  .gradient-content {
    h3 {
      font-size: 30px;
      margin-bottom: 0px;
      span {
        font-size: 35px;
      }
    }
    p {
      display: none;
    }
  }
  .gradient-bg {
    margin-bottom: 10px;
    margin-left: -10px;
  }
`;
