import React from "react";
import styled from "styled-components";
import data from "../value-vehicles/data";
import VehicleCard from "../../../../components/vehicleCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GradientText from "../../../../components/gradientText";
const ValueVehicles = () => {
  return (
    <Wrapper>
      <Row className="m-0">
        <Col className="p-0 mb-1" xs={12} sm={12} md={4}>
          <GradientText
            title={"Our Value"}
            gradientTitle={"Vehicles"}
            description={
              "Under the watchful eyes of experienced coaches, sessions unfold like symphonies of athleticism and strategy. Drills are meticulously planned, each designed to sharpen reflexes, enhance agility, and foster teamwork. Through sweat-soaked jerseys and relentless exertion, bonds are forged that transcend the boundaries of the pitch, binding teammates in a common pursuit of excellence."
            }
          />
        </Col>
        <Col className="p-0 mb-1" xs={12} sm={12} md={8}>
          <Row className="m-0">
            {data.map((item, index) => {
              return (
                <Col
                  className="p-0 mb-1"
                  xs={12}
                  sm={12}
                  md={index === 0 || index === 1 ? 6 : 3}
                  key={index}
                >
                  <VehicleCard item={item} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ValueVehicles;

const Wrapper = styled.div`
  font-family: "Poppins";
  background: #010101;
  padding: 5% 10% 5% 10%;
  @media (min-width: 100px) and (max-width: 1199px) {
    padding: 5% 6% 5% 6%;
  }
`;
