import React from "react";
import styled from "styled-components";

const HeadingLabel = ({ title, boldText }) => {
  return (
    <Wrapper className="title-label">
      <label>
        {title}
        <span className="bold-txt">{boldText ? boldText : ""}</span>
      </label>
    </Wrapper>
  );
};

export default HeadingLabel;
const Wrapper = styled.div`
  &.title-label {
    text-align: center;
    label {
      font-size: 20px;
      color: #ffffff;
      font-weight: 400;
      margin-top: 0px;
      padding-bottom: 40px;
    }
  }
  .bold-txt {
    font-weight: 900;
    padding-left: 7px;
  }
`;
