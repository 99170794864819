import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import WhoAreWeCard from "../../../../components/WhoAreWeCard";
import Logo from "../../assets/images/logo.png";
import { WhoWeAreData } from "../../../../components/WhoAreWeCard/data";
// import Logo from "../../pages/landing/assets/images/logo.png";

const WhoWeAre = () => {
  return (
    <Wrapper>
      <CustomContainer>
        <HeaderWrapper>
          <HeaderFlexWrapper>
            <HeaderLabel className="me-5">Who we are Pass</HeaderLabel>
            <img src={Logo} alt="" className="img-fluid" />
          </HeaderFlexWrapper>
          <HeaderSubLabel>
            The
            <GradientLabel>Number ONE</GradientLabel>
            Community sports program in the UAE
          </HeaderSubLabel>
        </HeaderWrapper>
        <CardWrapper>
          <Row className="m-0 w-100">
            {WhoWeAreData?.map((item, i) => {
              return (
                <Col className="card-who p-0" xs={12} sm={12} md={4} key={i}>
                  <ColWrapper>
                    <WhoAreWeCard
                      key={i}
                      url={item?.url}
                      topIcon={item?.topIcon}
                      gradientText={item?.gradientText}
                      spiralImg={item?.spiralImg}
                      text={item?.text}
                      footerText={item?.footerText}
                      gradientTextEnd={i === 2}
                    />
                  </ColWrapper>
                </Col>
              );
            })}
          </Row>
        </CardWrapper>
      </CustomContainer>
    </Wrapper>
  );
};

export default WhoWeAre;

const CustomContainer = styled.div``;
const Wrapper = styled.div`
  background: linear-gradient(#010101, #1e1e1e);
  position: relative;
  font-family: "Poppins";
  color: #fff;
  padding: 5% 10% 5% 10%;

  @media (min-width: 100px) and (max-width: 1199px) {
    padding: 5% 6% 5% 6%;
  }
`;
const HeaderWrapper = styled.div``;
const HeaderFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CardWrapper = styled.div`
  padding: 5% 0 0 0;
  margin: 0 -12px;
  .card-who {
    &:nth-of-type(odd) {
      .who-we-are-card {
        &:hover {
          .card-img {
            transform: translateX(20px);
          }
        }
      }
    }
  }
  .card-who {
    &:nth-of-type(even) {
      .who-we-are-card {
        .card-img {
          transform: translateX(20px);
        }
        &:hover {
          .card-img {
            transform: translateX(0);
          }
        }
      }
    }
  }
`;
const ColWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
`;

const HeaderLabel = styled.h3`
  font-size: clamp(40px, 3vw, 55px);
`;
const HeaderSubLabel = styled.h3`
  color: #a09d9d;
  font-size: 35px;
  margin-top: 10px;
`;
const GradientLabel = styled.span`
  padding: 0 20px;
  font-size: 45px;
  font-weight: 100;
  background: linear-gradient(90deg, #4065eb 0%, #62ff49 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
