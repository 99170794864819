import React from "react";
import styled from "styled-components";

const CustomButton = ({ ...props }) => {
  const {
    className,
    wrapperClassName,
    disabled,
    label1,
    label2,
    btnType,
    children,
    onClick,
  } = props;
  return (
    <ButtonWrapper
      className={`custom-btn-wrapper ${btnType ?? ""}   ${wrapperClassName}`}
    >
      <button
        className={` ${className ?? ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        {label1 ?? ""}
        {children ?? ""}
        {label2 ?? ""}
      </button>
    </ButtonWrapper>
  );
};

export default CustomButton;
const ButtonWrapper = styled.div`
  &.square {
    button {
      border-radius: 0px;
      padding: 2px 20px;
      min-height: 40px;
      min-width: 125px;
      background: rgb(255 255 255 / 80%);
      color: #000000;
    }
  }
  button {
    width: 100%;
    background: #3f4a5b;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    padding: 6px 15px;
    border: 1px solid #ffffff;
    min-height: 32px;
  }
`;
