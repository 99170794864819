import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GradientText from "../../../../components/gradientText";
import UserCard from "../../../../components/usercard";
import data from "../journey/data";

const Journey = () => {
  return (
    <Wrapper>
      <GradientText title={"My"} gradientTitle={"Journey"} />
      <GridWrapper>
        <Row>
          {data.map((item, index) => {
            return (
              <Col className="styled-col" xs={12} sm={12} md={4} key={index}>
                <UserCard item={item} />
              </Col>
            );
          })}
        </Row>
      </GridWrapper>
    </Wrapper>
  );
};

export default Journey;

const Wrapper = styled.div`
  font-family: "Poppins";
  background: #181818;
  color: #ffffff;
  padding: 5% 10% 5% 10%;

  @media (min-width: 100px) and (max-width: 1199px) {
    padding: 5% 6% 5% 6%;
  }
`;
const GridWrapper = styled.div`
  padding: 15px 0 0 0;
  & > div {
    margin: 0 -25px !important;
  }
  .styled-col {
    padding: 0 25px 55px 25px;
    @media (min-width: 992px) and (max-width: 1319px) {
      flex: 0 0 auto;
      width: 50%;
    }
    @media (min-width: 1320px) and (max-width: 1365px) {
      padding-right: 40px;
      padding-bottom: 40px;
    }
    @media (min-width: 1366px) and (max-width: 1559px) {
      padding-right: 45px;
      padding-bottom: 45px;
    }
  }
`;
