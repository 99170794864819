import Img1 from "./assets/images/1.png";
import Img2 from "./assets/images/2.png";
import Img3 from "./assets/images/3.png";
import Img4 from "./assets/images/4.png";

const data = [
  {
    image: Img1,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img2,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img3,
    title: "Lorem ipsum dolor sit amet",
    price: "$10",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img4,
    title: "Lorem ipsum dolor sit amet",
    price: "$1450",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img1,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img2,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img3,
    title: "Lorem ipsum dolor sit amet",
    price: "$10",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img4,
    title: "Lorem ipsum dolor sit amet",
    price: "$1450",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img1,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img2,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img3,
    title: "Lorem ipsum dolor sit amet",
    price: "$10",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img4,
    title: "Lorem ipsum dolor sit amet",
    price: "$1450",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img1,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
  {
    image: Img2,
    title: "Lorem ipsum dolor sit amet",
    price: "$100",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img3,
    title: "Lorem ipsum dolor sit amet",
    price: "$10",
    reviewCount: 35,
    isNew: false,
    rating: 5,
  },
  {
    image: Img4,
    title: "Lorem ipsum dolor sit amet",
    price: "$1450",
    reviewCount: 35,
    isNew: true,
    rating: 5,
  },
];
export default data;
